import React from 'react'

import{
  MdEmail
} from 'react-icons/md'

export default function Footer() {
  return (
    <div className='w-screen h-full bg-zinc-900 py-4 pb-8 px-2 font-outfit max-h-[150px]'>
        <div className=' border-t-2 border-[#f3af78]  flex flex-col max-w-[1240px] px-2 pt-2 mx-auto justify-between sm:flex-row text-center text-white'>
          <p className='font-bold'>© 2022 Phil Ganem Jr</p>
          <div className='pt-2 sm:pt-0'>
            <div className='flex flex-row gap-1 justify-center md:justify-start'>
              <p className='font-bold text-[#f3af78]'>Call Now:</p>
              <a className='hover:text-[#f3af78]' href="tel:+1-207-232-1819"><p className='px-1'>(207)-232-1819</p></a>
            </div>
            <div className='flex flex-row gap-1 justify-center md:justify-start pt-2'>
              <p className='font-bold text-[#f3af78]'>Email:</p>
              <a class="hover:text-[#f3af78]" aria-disabled="false" target="_self" href="mailto:philsheavyequipment@live.com?subject=Hello!">
                <p className='px-1'>philsheavyequipment@live.com</p>
              </a>
            </div>
            <div className='flex flex-row gap-1 justify-center md:justify-start pt-2'>
              <p className='font-bold text-[#f3af78]'>Address:</p>
              <a class="hover:text-[#f3af78]" target="_blank" href="https://goo.gl/maps/QYB6A9D8sgPPmHjR9">
                <p className='px-1'>7 Maplewood Dr, Naples, ME 04055</p>
              </a>
            </div>
          </div>
        </div>
    </div>
  )
}