import React from 'react'

export default function head() {
  return (
    <div className="p-8 md:pt-24 h-screen w-screen flex items-center justify-center bg-crane1 bg-no-repeat bg-cover bg-blend-normal bg-center shadow-2xl">
        <div className='grid md:grid-cols-2 max-w-[1540px] m-auto md:gap-5 '>
            <div className='max-w-[1500px] flex flex-col sm:col-span-1 bg-zinc-800 rounded-2xl py-8 px-4 md:px-0'>
                <h1 className='text-center md:text-left text-4xl md:text-5xl font-bold font-heebo text-white out pl-0 md:pl-4'>Phil's Heavy Equipment Repair</h1>
                <p className='text-2xl md:text-4xl text-white text-center md:text-left font-heebo pl-0 md:pl-4 pt-2'>Heavy equipment repairs and inspections since 19XX</p>
            </div>
        </div>
    </div>
  )
}
