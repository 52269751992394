import React from 'react'
import { useForm, ValidationError } from '@formspree/react';
import ThankYou from './ThankYou';


export default function Contact() {

  const [state, handleSubmit] = useForm("mayvzwrv");
  if (state.succeeded) {
      return <ThankYou />
  }


  return (
    <div id='Contact' className='w-screen h-full bg-zinc-600 pt-10 font-heebo pb-8 '>
        <div className='grid grid-cols-1 items-center text-center'>
            <h1 className='font-bold text-4xl text-[#d68848]'>Contact</h1>
            <h2 className='text-white pt-4 px-2 text-2xl'>Have a business inquiry or would like to get in contact about a potental job? Use our email form below!</h2>
        </div>
      <div className='grid place-items-center w-full pt-8'>
        <form className='rounded-2xl w-[300px] h-[400px] md:w-[500px]' onSubmit={handleSubmit}>
            <div className='grid  grid-cols-1 max-w-[500px] justify-center text-black p-4 bg-zinc-700 rounded-2xl'>
              <div className='grid grid-cols-2 gap-2'>
                <label className='font-bold text-[#f3af78] pb-2' htmlFor="email">Email Address</label>
                <input className='rounded-md focus:outline-none focus:ring focus:ring-zinc-700 ' id="email" type="email" name="email" required={true}/>
                <p className='font-bold text-[#f3af78] pb-2 pt-2'>Full Name</p>
                <input className='rounded-md focus:outline-none focus:ring focus:ring-zinc-700 ' id="Name" name="Name" required={true}/>
                <ValidationError prefix="Email"  field="email" errors={state.errors}/> 
              </div>
              <p className='font-bold text-[#f3af78] pb-2 pt-4'>Message</p>
              <textarea className='border h-[150px] rounded-md resize-none focus:outline-none focus:ring focus:ring-zinc-700 ' required={true} id="message" name="message"/>
              <ValidationError prefix="Message" field="message"errors={state.errors}/>
              <div className='pb-2 pt-5 text-center'>
                <button className='font-bold hover:bg-zinc-200 text-[#a1836b] pb-2 pt-2 bg-white rounded-xl w-[100px] text-center focus:outline-none focus:ring focus:ring-violet-300 ' type="submit" disabled={state.submitting}>Submit</button>
              </div>
            </div>
          </form>
      </div>
    </div>
)
}
