import React from 'react'

export default function ThankYou() {
  return (
    <div className='w-screen h-full bg-zinc-600 pt-10 font-heebo pb-20 '>
        <div className='grid grid-cols-1 items-center text-center'>
            <h1 className='font-bold text-4xl text-[#d68848]'>Contact</h1>
        </div>
      <div className='grid place-items-center h-full'>
            <div className='grid grid-cols-1 max-w-[500px] justify-center text-black bg-[#d68848] p-4 shadow-lg rounded-xl '>
              <h1 className='p-10 text-white'>Thank You! We'll get in touch</h1>
            </div>
      </div>
    </div>
  )
}
