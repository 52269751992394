import React from 'react'

import crane from '../assets/shutterstock_746685397-3337672145.jpg'


export default function About() {
  return (
    // pb-20 is temp, remove later 
    <div id='About' className='bg-zinc-600 font-heebo pt-10 text-white pb-20 '>
        <div className='grid grid-cols-1 items-center text-center'>
            <h1 className='font-bold text-4xl text-[#d68848]'>
                About the Company
            </h1>
            <div className='p-4 pt-10 px-2 md:px-12'>
                <p className='xl:text-2xl'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo sunt dolores aliquid incidunt
                    esse quo eum laborum, ipsum eligendi tenetur maxime reprehenderit harum illo. Soluta illum magni
                    atque libero! Sequi fuga beatae dicta error deserunt iste dignissimos commodi quaerat vel laboriosam
                    consequatur nihil est officia obcaecati, voluptatum praesentium accusamus quasi libero doloribus distinctio
                    modi nisi iusto suscipit et? Exercitationem illum quisquam veritatis aperiam doloribus commodi tempore minus! Corrupti,
                    dicta veniam magni voluptatibus et laborum inventore, sint autem est suscipit ea voluptates sapiente ipsam aliquid
                    omnis libero asperiores assumenda ipsa consectetur quia recusandae officiis distinctio? Quia perferendis modi tempora
                    vitae necessitatibus porro odit velit praesentium quaerat similique repudiandae animi, vero, culpa reprehenderit sint
                    adipisci, dignissimos laborum repellat a maiores quae. Esse inventore fuga delectus iste. Eligendi earum aut, rem
                    corrupti omnis ab recusandae ipsam perspiciatis et ipsa fugit dolor laborum eum doloribus odit mollitia maxime neque ipsum quo iste veniam ex.
                </p>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 md:px-20 pt-10'>
              <div className='flex justify-center'>
                <img className='max-w-auto max-h-auto sm:max-w-xl sm:max-h-xl' src={crane} alt="/" />

              </div>
              <div className='flex justify-center items-center '>
                <div>
                  <p className='xl:text-lg'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa neque nemo
                    nostrum inventore incidunt fugit eius doloribus modi pariatur est iusto eaque illo
                    , sed iste mollitia eum asperiores aliquam aperiam, facere laborum provident earum, et reprehenderit.
                    Temporibus illum, culpa quae natus vitae accusamus amet error praesentium fugit? Placeat soluta
                    libero ea necessitatibus possimus esse incidunt nisi, laborum animi corrupti id ipsum labore,
                    voluptates earum dicta cumque? Doloribus ipsum velit impedit! Suscipit nemo officiis officia saepe,
                    quibusdam quod enim quam commodi.
                  </p>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}
