import React from 'react'
import crane from '../assets/shutterstock_746685397-3337672145.jpg'


export default function Jobs() {
  return (
    <div id='Jobs' className='bg-zinc-700 h-full w-screen text-white pt-10 pb-20 font-heebo text-center'>
        <div className='grid grid-cols-1 items-center text-center'>
            <h1 className='font-bold text-4xl text-[#d68848]'>What We Do</h1>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 md:px-20 pt-10'>
          <div>
            <img src={crane} alt="/" />

          </div>
          <div className='flex justify-center items-center '>
            <div>
              <h1 className='text-center text-2xl xl:text-4xl pb-2 text-[#f3af78]'>Repairs</h1>
              <p className='xl:text-2xl'>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore aut facere officia enim, quo mollitia explicabo quos
                odio similique, autem non rerum. Mollitia a, consectetur obcaecati asperiores, modi fugiat ad perspiciatis molestiae
                commodi aut ab temporibus perferendis reprehenderit expedita? Sapiente maiores laborum exercitationem cumque illo asperiores
                quisquam commodi, ipsum repudiandae.
              </p>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 md:px-20 pt-16'>
          <div className='flex lg:hidden'>
            <img src={crane} alt="/" />
          </div>
          <div className='flex justify-center items-center '>
            <div>
            <h1 className='text-center text-2xl xl:text-4xl pb-2 text-[#f3af78]'>Inspections</h1>
            <p className='xl:text-2xl'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore aut facere officia enim, quo mollitia explicabo quos
              odio similique, autem non rerum. Mollitia a, consectetur obcaecati asperiores, modi fugiat ad perspiciatis molestiae
              commodi aut ab temporibus perferendis reprehenderit expedita? Sapiente maiores laborum exercitationem cumque illo asperiores
              quisquam commodi, ipsum repudiandae.
            </p>
            </div>
          </div>
          <div className='hidden lg:flex'>
            <img src={crane} alt="/" />
          </div>
        </div>
    </div>
  )
}
