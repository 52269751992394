import React from "react";
import Navbar from "./components/Navbar";
import Head from "./components/Head";
import Footer from "./components/Footer";
import About from "./components/About";
import Jobs from "./components/Jobs";
import Contact from "./components/Contact";



function App() {
  return (
    <>
    <div className='grid grid-cols-1 h-screen w-screen'>
      <Navbar />
      <Head />
      <About />
      <Jobs />
      <Contact />
      <Footer />
    </div>
    </>
  );
}

export default App;
